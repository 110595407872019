@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

input {
  outline: none;
}

.container { 
  width: 1440px;
  margin: 0 auto !important;
}

.print-font-family div {
  font-family: "DejaVu Sans", "Arial", sans-serif;
}




@tailwind base;
@tailwind components;
@tailwind utilities;
