.pagination-btn {
  padding: 0px 10px;
  background-color: #f1f5f9;
  margin: 10px;
  border-radius: 4px;
  color: #3a416f;
  cursor: pointer;
}

.pagination-btn:hover {
  background-color: #3c4d9f9a;
  color: white;
  transition: ease-in 300ms;
}

.active-pagination-btn {
  background-color: #3c4d9f;
  color: white;
}
