::-webkit-scrollbar {
  width: 2px; 
}

::-webkit-scrollbar-thumb {
  background-color: #1e40af2c; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1E40AF; 
}

.custom-hover-bg {
  position: relative;
}
.custom-hover-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; 
  width: 100%;
  height: 100%;
  background-color: rgba(159, 159, 159, 0.084); 
  z-index: -1;
  transition: left 0.3s ease-in-out;
}
.custom-hover-bg:hover::before {
  left: 0; 
}
